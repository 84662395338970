<template>
  <v-card color="transparent" class="pt-0 elevation-0 expande-horizontal wrap">
    <div class="expande-horizontal wrap pb-0">
      <v-flex xs12 md6>
        <v-flex xs12>
          <v-form ref="form">
            <v-timeline align-top dense>
              <v-timeline-item color="orange" icon="mdi-storefront">
                <v-list-item class="pt-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte orange--text">
                      Configurações do Local
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Defina opções para quando o pedido for consumido dentro do
                      seu estabelecimento
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex xs12 class="px-1">
                  <v-list color="transparent">
                    <v-list-item class="item-list mb-2">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          Indique que o pedido deve ser feito diretamente com o
                          garçom
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch color="green"></v-switch>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item class="item-list mb-2">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          Pagar Pelo Menu Rocket
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch color="green"></v-switch>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item class="item-list">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          Permitir Retirada no Local?
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch color="green"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </v-timeline-item>

              <v-timeline-item color="orange" icon="mdi-cart">
                <v-list-item class="pt-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte orange--text">
                      Configurações Delivery
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Configurações para entrega de pedidos.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex xs12 class="px-1">
                  <v-list color="transparent">
                    <v-list-item class="item-list mb-2">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          Ativar Delivery?
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch color="green"></v-switch>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item class="item-list mb-2">
                      <v-list-item-content>
                        <v-list-item-title class="">
                          Quantidade mínima para fazer a entrega ( sem
                          considerar a taxa de entrega )
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-text-field
                            v-mask="['#', '##', '###']"
                            :color="$theme.primary"
                            solo-inverted
                            hide-details
                            placeholder="0,00"
                            prefix="R$"
                            dense
                          ></v-text-field>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <!-- <v-switch color="green"></v-switch> -->
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </v-timeline-item>
            </v-timeline>
          </v-form>
        </v-flex>
        <div class="expande-horizontal px-6">
          <v-btn
            class="elevation-0"
            rounded
            dark
            @click="valida_form"
            :color="$theme.primary"
          >
            <span class="fonte"> Salvar </span>
          </v-btn>
        </div>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  data() {
    return {
      novo_link_dialog: false,
      novo_link_content: {}
    };
  },
  computed: {
    ...mapGetters(["get_configurarpedido", "getLinks"])
  },
  methods: {
    ...mapActions([
      "atualizar_funcionario",
      "abre_modal_view_configurarpedido"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_configurarpedido._id
          ? this.atualizar_funcionario()
          : this.criar_funcionario();
      }
    },
    setRetirada(val) {
      this.$store.commit("set_configurarpedido", {
        ...this.get_configurarpedido,
        retirada: val
      });
    },
    setDelivery(val) {
      this.$store.commit("set_configurarpedido", {
        ...this.get_configurarpedido,
        delivery: val
      });
    },
    openLink(item) {
      window.open(item.link, "_blank");
    }
  },
  created() {
    if (!this.get_configurarpedido.retirada) {
      this.get_configurarpedido.retirada = false;
    }
    if (!this.get_configurarpedido.delivery) {
      this.get_configurarpedido.delivery = false;
    }
    if (!this.get_configurarpedido.links) {
      this.get_configurarpedido.links = [];
    }
    // this.$store.commit("setLinks", this.get_configurarpedido.photo);
  }
};
</script>

<style>
.cel {
  width: 300px;
  height: 620px;
  border-right: 2px solid #333;
  border-left: 2px solid #333;
  border-radius: 6px;
  border-top: 12px solid #333;
  border-bottom: 18px solid #333;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #c480ff, #8e2de2);
}
.item-list {
  border-radius: 6px;
  background-color: #fff;
}
</style>
